<template>
    <b-form id="submitDrivers" class="needs-validation" @submit.stop.prevent="saveVehicle">
        <b-row>
            <b-col :md="4">
                <b-form-group invalid-feedback="This field is required">
                    <b-row slot="label">
                        <b-col cols="8"> Vehicle # {{ form.id }} VIN number </b-col>
                        <b-col cols="4" class="text-right">
                            <span class="text-danger pointer" @click="$emit('click:remove', form.id)" v-if="form.id !== 1"> Remove </span>
                        </b-col>
                    </b-row>
                    <b-form-input v-model="form.vin" :state="validateState('vin')" trim />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                        :id="`vehicle-types-${form.id}`"
                        v-model="form.type"
                        :options="types"
                        :aria-describedby="ariaDescribedby"
                        :name="`vehicle-types-${form.id}`"
                        :state="validateState('type')"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col :md="3" class="pt-2" v-show="form.type === 'other'">
                <b-form-group label="What type of vehicle is this?" invalid-feedback="This field is required">
                    <b-form-input v-model="form.type_other" :state="validateState('type_other')" trim />
                </b-form-group>
            </b-col>

            <b-col :md="3" class="pt-2">
                <b-form-group label="Purchase Date" invalid-feedback="This field is required">
                    <b-form-input type="date" v-model="form.purchase_date" :state="validateState('purchase_date')" trim />
                </b-form-group>
            </b-col>

            <b-col :md="3" class="pt-2">
                <b-form-group label="Monitoring Device?" invalid-feedback="This field is required">
                    <b-form-select v-model="form.monitoring_device" :state="validateState('monitoring_device')" :options="yesOrNo" />
                </b-form-group>
            </b-col>

            <b-col :md="3" class="pt-2">
                <b-form-group label="Roadside Assistance?" invalid-feedback="This field is required">
                    <b-form-select v-model="form.roadside_assistance" :state="validateState('roadside_assistance')" :options="yesOrNo" />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-button type="submit" variant="purple"> Save </b-button>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import { yesOrNo } from "@/data/select";

export default {
    name: "VehicleForm",

    mixins: [validationMixin],

    validations: {
        form: {
            vin: { required },
            type: { required },
            purchase_date: { required },
            monitoring_device: { required },
            roadside_assistance: { required },
            type_other: {
                required: requiredIf((vehicle) => vehicle.type === "other"),
            },
        },
    },

    data() {
        return {
            form: this.$props.field,
            types: [
                { text: "Car", value: "car" },
                { text: "Motorcycle", value: "motorcycle" },
                { text: "RV", value: "rv" },
                { text: "Boat", value: "boat" },
                { text: "Other", value: "other" },
            ],
            yesOrNo,
        };
    },

    props: {
        field: {
            type: Object,
            required: true,
        },
    },

    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },

        saveVehicle() {
            this.$v.form.$touch();

            if (this.$v.form.$anyError) {
                return;
            }
            this.$toast.success('Vehicle saved')
            this.$store.commit("addVehicle", this.form);
            this.$emit("addVehicle", this.form);
        },
    },
};
</script>