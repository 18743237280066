<template>
    <b-form id="submitApplicationInformation" @submit.stop.prevent="submitApplicantInformation">
        <h2 class="font-weight-bold pb-4">Applicant Information</h2>

        <b-row>
            <b-col :md="3">
                <form-group :validations="$v.form.first_name" label="First Name">
                    <b-form-input v-model="form.first_name" :state="validateState('first_name')" />
                </form-group>
            </b-col>

            <b-col :md="3">
                <form-group label="Middle name" :validations="null">
                    <b-form-input v-model="form.middle_name" trim />
                </form-group>
            </b-col>
            <b-col :md="3">
                <form-group label="Last name" :validations="$v.form.last_name">
                    <b-form-input v-model="form.last_name" :state="validateState('last_name')" trim />
                </form-group>
            </b-col>
            <b-col :md="3">
                <form-group label="Date of Birth" :validations="$v.form.dob">
                    <b-form-input v-model="form.dob" placeholder="MM/DD/YYYY" :state="validateState('dob')" trim />
                </form-group>
            </b-col>
        </b-row>

        <b-row class="pt-2 pt-md-0">
            <b-col :md="6">
                <form-group label="Address" :validations="$v.form.address">
                    <b-form-input v-model="form.address" placeholder="1234 Main St" :state="validateState('address')" trim />
                </form-group>
            </b-col>
            <b-col :md="6">
                <b-form-group label="Address 2">
                    <b-form-input v-model="form.address2" placeholder="Apartment, studio or floor" trim />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row class="pt-2 pt-md-0">
            <b-col :md="4">
                <form-group label="City" :validations="$v.form.city">
                    <b-form-input v-model="form.city" :state="validateState('city')" trim />
                </form-group>
            </b-col>
            <b-col :md="4">
                <form-group label="State" :validations="$v.form.state">
                    <b-form-select v-model="form.state" :options="states" :state="validateState('state')"></b-form-select>
                </form-group>
            </b-col>
            <b-col :md="4">
                <form-group label="Zip" :validations="$v.form.zipcode">
                    <b-form-input v-model="form.zipcode" :state="validateState('zipcode')" trim />
                </form-group>
            </b-col>

            <b-col :md="6">
                <form-group label="Email" :validations="$v.form.email">
                    <b-form-input v-model="form.email" :state="validateState('email')" trim />
                </form-group>
            </b-col>
            <b-col :md="6">
                <form-group label="Phone Number" :validations="$v.form.phone_number">
                    <b-form-input v-model="form.phone_number" placeholder="000-000-0000" :state="validateState('phone_number')" trim />
                </form-group>
            </b-col>
        </b-row>

        <b-row class="pt-2">
            <b-col :sm="3" align-self="center">
                <h5 class="font-weight-bold">Time at Residence</h5>
            </b-col>

            <b-col :md="4">
                <form-group label="Years" :validations="$v.form.time_at_residence_years">
                    <b-form-input type="number" v-model="form.time_at_residence_years" :state="validateState('time_at_residence_years')" />
                </form-group>
            </b-col>

            <b-col :md="5">
                <form-group label="Months" :validations="$v.form.time_at_residence_months">
                    <b-form-input
                        type="number"
                        v-model="form.time_at_residence_months"
                        :state="validateState('time_at_residence_months')"
                    />
                </form-group>
            </b-col>
        </b-row>

        <b-row class="pt-2">
            <b-col class="mb-0">
                <hr />
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <h2 class="font-weight-bold pb-4">General Information / Coverages</h2>
            </b-col>
        </b-row>

        <b-row>
            <b-col :md="6">
                <form-group label="Policy Term" :validations="$v.form.policy_term">
                    <b-form-select v-model="form.policy_term" :options="policyTerms" :state="validateState('policy_term')"/>
                </form-group>
            </b-col>

            <b-col :md="6">
                <form-group label="Policy Payments" :validations="$v.form.policy_payment">
                    <b-form-select v-model="form.policy_payment" :options="policyPayments" :state="validateState('policy_payment')"/>
                </form-group>
            </b-col>

            <b-col :md="6">
                <form-group label="Coverage Type" :validations="$v.form.coverage_type">
                    <b-form-select v-model="form.coverage_type" :options="coverageTypes" :state="validateState('coverage_type')"/>
                </form-group>
            </b-col>

            <b-col :md="6">
                <form-group label="Starting Date" :validations="$v.form.starting_date">
                    <b-form-input type="date" v-model="form.starting_date" :state="validateState('starting_date')" />
                </form-group>
            </b-col>

            <b-col :md="6">
                <form-group label="Use Credit Score?" :validations="$v.form.use_credit_score">
                    <b-form-select v-model="form.use_credit_score" :state="validateState('use_credit_score')">
                        <b-form-select-option :value="null" disabled>Please select one</b-form-select-option>
                        <b-form-select-option value="yes">Yes</b-form-select-option>
                        <b-form-select-option value="no">No</b-form-select-option>
                    </b-form-select>
                </form-group>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { states } from "@/data/states";
import { policyTerms, policyPayments, coverageTypes } from "@/data/select";
import { page1FakeData } from "@/data/fakedata";
import FormGroup from "@/components/FormGroup.vue";
import { minv } from "@/support/rules";

export default {
    name: "Page1",

    mixins: [validationMixin],

    components: {
        FormGroup,
    },

    validations: {
        form: {
            first_name: { required },
            last_name: { required },
            dob: { required },
            address: { required },
            city: { required },
            state: { required },
            zipcode: { required },

            email: { required },
            phone_number: { required },

            policy_term: { required },
            policy_payment: { required },
            coverage_type: { required },
            starting_date: { required },
            use_credit_score: { required },
            time_at_residence_years: { required },

            time_at_residence_months: {
                required,
                minValue: minv(1),
            },
        },
    },

    data() {
        return {
            form: {
                first_name: null,
                middle_name: null,
                last_name: null,
                dob: null,
                address: null,
                address2: null,
                city: null,
                state: null,
                zipcode: null,
                email: null,
                phone_number: null,
                policy_term: null,
                policy_payment: null,
                coverage_type: null,
                starting_date: null,
                use_credit_score: null,
                time_at_residence_years: 0,
                time_at_residence_months: 0,
            },

            states,
            policyTerms,
            policyPayments,
            coverageTypes,
        };
    },

    mounted() {
        this.form = page1FakeData;
    },

    methods: {
        submitApplicantInformation() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.$store.commit('updateApplicationData', this.form)
            this.$v.$reset();
            this.$emit("complete");
        },

        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
    },
};
</script>

<style>
tr,
th,
td {
    padding: 0 !important;
}
</style>