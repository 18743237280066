<template>
    <div>
        <b-row class="pb-4">
            <b-col>
                <h2 class="font-weight-bold">Vehicle Information</h2>
            </b-col>
            <b-col class="text-right">
                <b-button variant="purple" @click="addVehicle('name', 'id')">Add vehicle</b-button>
            </b-col>
        </b-row>
        <div v-for="vehicle in vehicles" :key="vehicle.id">
            <vehicle-form :field="vehicle" @click:remove="removeVehicle" />
            <hr />
        </div>
    </div>
</template>
<script>
import VehicleForm from "./vehicle-form.vue";

export default {
    name: "Page3",

    components: {
        VehicleForm,
    },

    data() {
        return {
            vehicles: [
                {
                    type: "car",
                    type_other: null,
                    vin: null,
                    purchase_date: null,
                    monitoring_device: null,
                    roadside_assistance: null,
                    name: 1,
                    id: 1,
                },
            ],
        };
    },

    methods: {
        saveVehicle() {},

        addVehicle() {
            this.vehicles.push({
                id: this.vehicles.length + 1,
                vin: null,
                type: null,
                type_other: null,
                monitoring_device: null,
                roadside_assistance: null,
                purchase_date: null,
            });
        },

        removeVehicle(id) {
            this.$store.commit('removeVehicle', id);
            
            this.$delete(
                this.vehicles,
                this.vehicles.findIndex((o) => o.id === id),
            );
        },
    },
};
</script>