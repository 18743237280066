<template>
    <b-form id="submitDrivers" @submit.stop.prevent="saveDriver" v-if="currentDriver">
        <div id="top" ref="top"></div>
        <b-row class="pb-4" v-if="!editing">
            <b-col>
                <h2 class="font-weight-bold">Drivers</h2>
            </b-col>
        </b-row>

        <b-table :fields="driverTableFields" v-if="drivers.length > 0 && !editing" :items="getDrivers" table-class="p-0">
            <template #cell(first_name)="row">
                {{ row.item.first_name ? row.item.first_name : "N/A" }}
            </template>
            <template #cell(edit)="row">
                <b-link @click="editDriver(row.item.id)" class="mr-1"> Edit </b-link>
            </template>
            <template #cell(remove)="row">
                <b-link class="text-danger" v-if="row.item.id !== 1" @click="removeDriver(row.item.id)"> Remove </b-link>
            </template>
        </b-table>

        <b-row align-v="center" align-content="center">
            <b-col align-self="center" class="text-center w-100">
                <b-button variant="primary" class="w-100" @click="addDriver" v-if="!editing"> Add another driver </b-button>
            </b-col>
        </b-row>

        <div v-if="editing">
            <h2 class="font-weight-bold pb-4">{{ header1 }}</h2>
            <b-row>
                <b-col :md="4">
                    <form-group label="First name" :validations="$v.form.first_name">
                        <b-form-input v-model="form.first_name" :state="validateState('first_name')" trim />
                    </form-group>
                </b-col>

                <b-col md="4">
                    <b-form-group label="Middle name">
                        <b-form-input v-model="form.middle_name" />
                    </b-form-group>
                </b-col>
                <b-col :md="4">
                    <form-group label="Last name" :validations="$v.form.last_name">
                        <b-form-input v-model="form.last_name" :state="validateState('last_name')" trim />
                    </form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col :md="4">
                    <form-group label="Date of Birth" :validations="$v.form.dob">
                        <b-form-input v-model="form.dob" :state="validateState('dob')" placeholder="MM/DD/YYYY" trim />
                    </form-group>
                </b-col>

                <b-col :md="4">
                    <form-group label="Gender" :validations="$v.form.gender">
                        <b-form-select v-model="form.gender" :state="validateState('gender')" :options="genders" />
                    </form-group>
                </b-col>

                <b-col :md="4">
                    <form-group label="Marital Status" :validations="$v.form.marital_status">
                        <b-form-select v-model="form.marital_status" :options="maritalStatuses" :state="validateState('marital_status')" />
                    </form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col :md="4">
                    <form-group label="Drivers License Number" :validations="$v.form.drivers_license_number">
                        <b-form-input v-model="form.drivers_license_number" :state="validateState('drivers_license_number')" trim />
                    </form-group>
                </b-col>

                <b-col :md="4">
                    <form-group label="Drivers SSN" :validations="$v.form.drivers_ssn">
                        <b-form-input v-model="form.drivers_ssn" :state="validateState('drivers_ssn')" trim />
                    </form-group>
                </b-col>

                <b-col :md="4">
                    <form-group label="License Status" :validations="$v.form.license_status">
                        <b-form-select v-model="form.license_status" :state="validateState('license_status')" :options="licenseStatuses" />
                    </form-group>
                </b-col>
            </b-row>
            
            <b-row>
                <b-col :md="6">
                    <form-group label="Residence Type" :validations="$v.form.residence_type">
                        <b-form-select v-model="form.residence_type" :state="validateState('residence_type')" :options="residenceTypes" />
                    </form-group>
                </b-col>

                <b-col :md="6">
                    <form-group label="Residence Status" :validations="$v.form.residence_status">
                        <b-form-select
                            v-model="form.residence_status"
                            :state="validateState('residence_status')"
                            :options="residenceStatuses"
                        />
                    </form-group>
                </b-col>
            </b-row>

            <b-row class="pt-4">
                <b-col>
                    <h2 class="font-weight-bold">{{ header2 }}</h2>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="4">
                    <form-group label="Prior Insurance?" :validations="$v.form.prior_insurance">
                        <b-form-select v-model="form.prior_insurance" :state="validateState('prior_insurance')" :options="yesOrNo" />
                    </form-group>
                </b-col>
                <b-col md="4" v-show="form.prior_insurance === 'yes'">
                    <form-group label="Prior Insurance Carrier" :validations="$v.form.prior_insurance_carrier">
                        <b-form-input v-model="form.prior_insurance_carrier" :state="validateState('prior_insurance_carrier')" trim />
                    </form-group>
                </b-col>

                <b-col md="4" v-show="form.prior_insurance === 'yes'">
                    <form-group label="Expiration Date" :validations="$v.form.prior_insurance_expiration_date">
                        <b-form-input
                            type="date"
                            v-model="form.prior_insurance_expiration_date"
                            :state="validateState('prior_insurance_expiration_date')"
                            trim
                        />
                    </form-group>
                </b-col>
            </b-row>

            <b-row class="pt-2">
                <b-col md="4">
                    <form-group label="Foreign Licensed?" :validations="$v.form.foreign_licensed">
                        <b-form-select v-model="form.foreign_licensed" :state="validateState('foreign_licensed')" :options="yesOrNo" />
                    </form-group>
                </b-col>

                <b-col md="4" v-show="form.foreign_licensed === 'yes'">
                    <form-group label="Foreign Driving Experience Years" :validations="$v.form.foreign_driving_experience_years">
                        <b-form-input
                            v-model="form.foreign_driving_experience_years"
                            :state="validateState('foreign_driving_experience_years')"
                            trim
                        />
                    </form-group>
                </b-col>

                <b-col md="4" v-show="form.foreign_licensed === 'yes'">
                    <form-group label="Foreign Driving Experience Months" :validations="$v.form.foreign_driving_experience_months">
                        <b-form-input
                            v-model="form.foreign_driving_experience_months"
                            :state="validateState('foreign_driving_experience_months')"
                            requiredtrim
                        />
                    </form-group>
                </b-col>
            </b-row>

            <b-row class="pt-2">
                <b-col :md="4">
                    <form-group label="Sr22?" :validations="$v.form.sr22">
                        <b-form-select v-model="form.sr22" :state="validateState('sr22')" :options="yesOrNo" />
                    </form-group>
                </b-col>

                <b-col md="4" v-show="form.sr22 === 'yes'">
                    <form-group label="Sr22 State Filed" :validations="$v.form.sr22_state_filed">
                        <b-form-select v-model="form.sr22_state_filed" :state="validateState('sr22_state_filed')" :options="states" />
                    </form-group>
                </b-col>

                <b-col md="4" v-show="form.sr22 === 'yes'">
                    <form-group label="Sr22 Reason" :validations="$v.form.sr22_reason">
                        <b-form-select v-model="form.sr22_reason" :state="validateState('sr22_reason')" :options="sr22Reasons" />
                    </form-group>
                </b-col>
            </b-row>

            <div class="d-inline-block">
                <b-button variant="primary" type="submit" class="mr-2" v-if="editing">
                    {{ saveOrAddNew ? "Save Driver" : "Create Driver" }}
                </b-button>
                <b-button variant="danger" @click="cancelDriver" v-if="editing && !firstVisit"> Cancel </b-button>
            </div>
        </div>
    </b-form>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { states } from "@/data/states";
import { driverTableFields } from "@/data/loops";
import { maritalStatuses, licenseStatuses, residenceTypes, residenceStatuses, sr22Reasons, genders, yesOrNo } from "@/data/select";
import FormGroup from "@/components/FormGroup.vue";
import collect from "collect.js";

export default {
    name: "Page2",

    mixins: [validationMixin],

    components: {
        FormGroup,
    },

    data() {
        return {
            form: this.currentDriver ?? {},
            firstVisit: true,
            sr22: null,
            sr22Reason: null,
            residenceStatus: null,
            driverTableFields,
            maritalStatuses,
            licenseStatuses,
            yesOrNo,
            states,
            residenceTypes,
            sr22Reasons,
            genders,
            residenceStatuses,
            editing: true,
        };
    },

    validations: {
        form: {
            first_name: { required },
            last_name: { required },
            dob: { required },
            gender: { required },
            drivers_license_number: { required },
            drivers_ssn: { required },
            license_status: { required },
            marital_status: { required },
            prior_insurance: { required },
            foreign_licensed: { required },
            sr22: { required },
            residence_type: { required },
            residence_status: { required },

            foreign_driving_experience_years: {
                required: requiredIf((form) => form.foreign_licensed === "yes"),
            },
            foreign_driving_experience_months: {
                required: requiredIf((form) => form.foreign_licensed === "yes"),
            },
            prior_insurance_carrier: {
                required: requiredIf((form) => form.prior_insurance === "yes"),
            },
            prior_insurance_expiration_date: {
                required: requiredIf((form) => form.prior_insurance === "yes"),
            },
            sr22_state_filed: {
                required: requiredIf((form) => form.sr22 === "yes"),
            },
            sr22_reason: {
                required: requiredIf((form) => form.sr22 === "yes"),
            },
        },
    },

    computed: {
        ...mapGetters({
            drivers: ["drivers"],
            currentDriver: ["currentDriver"],
        }),

        firstDriverIsValid() {
            return this.drivers[0] && this.drivers[0].valid;
        },

        saveOrAddNew() {
            var findExisting = collect(this.drivers).where("id", this.form.id).count();
            return findExisting > 0 ? true : false;
        },

        getDrivers() {
            return this.drivers
                .filter((o) => o.valid === true)
                .map(({ id, first_name, last_name, dob }) => ({ id, first_name, last_name, dob }));
        },

        header1() {
            if (this.firstVisit) {
                return "Driver Information";
            }
            return `Driver #${this.form.id} Information`;
        },

        header2() {
            if (this.firstVisit) {
                return "Driver Attributes";
            }
            return `Driver #${this.form.id} Attributes`;
        },
    },

    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },

        saveDriver() {
            this.$v.form.$touch();

            if (this.$v.form.$anyError) {
                return;
            }
            this.firstVisit = false;
            this.form.valid = true;

            const existingDriver = this.drivers.find((o) => o.id === this.form.id);

            if (existingDriver) {
                this.$store.commit("updateDriverData", this.form);
            } else {
                this.drivers.push(this.form);
            }
            this.$store.commit("stopEditing");
            this.scrollToTop();
            this.$toast.success('Driver added')

            this.editing = false;
        },

        cancelDriver() {
            this.editing = false;
            this.scrollToTop();
        },

        addDriver() {
            if (this.editing) return;
            this.$store.commit("addDriver");
            this.$v.$reset();
            this.editing = true;
        },

        editDriver(id) {
            this.$v.$reset();
            this.form = this.$store.getters.getById(id);
            this.editing = true;
        },

        removeDriver(id) {
            this.$store.commit("removeDriver", id);
            this.drivers.map((o) => {
                if (o.id !== 1) {
                    o.id -= 1;
                    return o;
                }
            });
        },

        scrollToTop() {
            this.$smoothScroll({
                scrollTo: this.$refs.top,
                offset: '-50'
            });
        },
    },

    watch: {
        currentDriver(value) {
            this.form = value;
        },
    },
};
</script>