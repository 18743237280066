export const policyTerms = [
    { value: null, text: "Please select an option", disabled: true },
    { text: "Annual (12 Months)", value: "annual" },
    { text: "Monthly", value: "monthly" },
    { text: "Quarterly (3 Months)", value: "quarterly" },
    { text: "Semi-Annual (6 Months)", value: "semi-annual" },
    { text: "Two Month", value: "twomonth" },
];

export const policyPayments = [
    { value: null, text: "Please select an option", disabled: true },
    { text: "Installments", value: "installments" },
    { text: "Installments EFT", value: "installments_eft" },
    { text: "Paid in Full", value: "paidinfull" },
    { text: "Paid in Full EFT", value: "paidinfull_eft" },
];

export const coverageTypes = [
    { value: null, text: "Please select an option", disabled: true },
    { text: "Liability", value: "liability" },
    { text: "Full coverage", value: "full_coverage" },
];

export const maritalStatuses = [
    { value: null, text: "Please select an option", disabled: true },
    { text: "Married", value: "married" },
    { text: "Single", value: "single" },
    { text: "Divorced", value: "divorced" },
    { text: "Widowed", value: "widowed" },
    { text: "Seperated", value: "seperated" },
    { text: "Domestic Partner", value: "domestic_partner" },
    { text: "Common Law", value: "common_law" },
];

export const licenseStatuses = [
    { value: null, text: "Please select an option", disabled: true },
    { text: "Valid", value: "valid" },
    { text: "Suspended", value: "suspended" },
    { text: "Revoked", value: "revoked" },
    { text: "Expired", value: "expired" },
];

export const genders = [
    { value: null, text: "Please select an option", disabled: true },
    { text: "Male", value: "male" },
    { text: "Female", value: "female" },
];

export const residenceTypes = [
    { value: null, text: "Please select an option", disabled: true },
    { text: "Home", value: "home" },
    { text: "Apartment", value: "apartment" },
    { text: "Condo", value: "condo" },
    { text: "Mobile Home", value: "mobile_home" },
    { text: "Fixed Mobile Home", value: "fixed_mobile_home" },
];

export const residenceStatuses = [
    { value: null, text: "Please select an option", disabled: true },
    { text: "Own", value: "own" },
    { text: "Rent", value: "rent" },
    { text: "Lease", value: "lease" },
];

export const sr22Reasons = [
    { value: null, text: "Please select an option", disabled: true },
    { text: "No Insurance", value: "no_insurance" },
    { text: "Accident with Death", value: "accident_with_death" },
    { text: "Accident with No Insurance", value: "accident_with_no_insurance" },
    { text: "Multiple Reckless/Majors", value: "multiple reckless_majors" },
    { text: "Drug/Alcohol Related", value: "drug_alcohol_related" },
    { text: "Mandatory Insurance Supervision", value: "mandatory_insurance_supervision" },
    { text: "Other", value: "other" },
];

export const yesOrNo = [
    { value: null, text: "Please select an option", disabled: true },
    { text: "Yes", value: "yes" },
    { text: "No", value: "no" },
];
