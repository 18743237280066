export default {
    validation: {
        alpha: "Alphanumeric characters only.",
        email: "Must be a valid email address.",
        minLength: "Must have a length no less than {{min}}.",
        required: "This field is required",
        requiredIf: "This field is required",
        minValue: "Minimum value is {{value}}",
    },
};
