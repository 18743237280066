<template>
    <b-container class="layout-wrapper">
        <v-style> body { background: #f4f4f4; } </v-style>

        <b-row>
            <div class="col-12 col-md-10" :class="{ 'col-12': page === 3 }">
                <!-- Panel 1 -->
                <div id="panel_1" class="pb-2" v-show="page === 0">
                    <Page1 ref="applicationForm" @complete="goForward" />
                </div>

                <!-- Panel 2 -->
                <div id="panel_2" v-show="page === 1">
                    <Page2 ref="driverForm" />
                </div>

                <!-- Panel 3 -->
                <div id="panel_3" v-show="page === 2">
                    <Page3 ref="vehicleForm" />
                </div>

                <!-- Panel 6 -->
                <div id="panel_4" v-show="page === 3">
                    <div class="text-center pt-5 col text-center">
                        <h1 class="pb-1 text-success text-center">Complete</h1>
                        <h3>One of our agents will contact you with your new quote shortly!</h3>
                        <div class="col-6 justify-content-center mx-auto mt-5">
                            <img src="@/assets/images/wellDone.svg" class="w-50" />
                        </div>
                    </div>

                    <div class="number col quoteHeader py-3 mt-5 text-center">
                        <router-link
                            class="quoteHeaderButton px-3 py-2 bold pointer"
                            style="text-decoration: none"
                            :to="{ name: 'landing' }"
                        >
                            Return home
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-2 progressBox d-flex flex-column pl-3 pt-3 pt-md-0" id="appStepCol" :style="page===3 ? 'display: none !important':null">
                <h4 class="">Application process</h4>

                <div class="col mx-0 px-0">
                    <ul class="mx-0 px-0">
                        <li class="list-group d-flex flex-column" v-for="(step, index) in steps" :key="index">
                            <div class="d-flex flex-row quoteStepTextColor">
                                <div class="mr-2">
                                    <b-icon-circle v-if="page <= index && page !== index" />
                                    <b-icon-circle-fill class="activeQuoteCircle" v-if="page === index" />
                                    <b-icon-check-circle-fill v-if="page >= index && page !== index" />
                                </div>
                                <div id="appProfileSetupText" :class="{ activeQuoteText: page === index }">
                                    {{ step }}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <b-row cols="1">
                    <b-col>
                        <b-button variant="outline-purple" class="mt-3 w-100" v-if="page !== 0" @click="goBack"> Go Back </b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="purple" type="submit" class="mt-3 w-100" :form="getCurrentForm" v-if="page === 0">
                            Next
                        </b-button>
                        <b-button variant="purple" class="mt-3 w-100" @click="goForward" v-if="page === 1"> Next </b-button>
                    </b-col>
                    <b-col v-if="page === 2">
                        <b-button class="mt-3 w-100" variant="purple" @click="submitQuote">Submit Quote</b-button>
                    </b-col>
                </b-row>
            </div>
        </b-row>
    </b-container>
</template>

<script>
import Page1 from "./form/page1.vue";
import Page2 from "./form/page2.vue";
import Page3 from "./form/page3.vue";
import { mapGetters } from "vuex";
import createDriver from "@/support/createDriver";

export default {
    name: "auto",

    components: {
        Page1,
        Page2,
        Page3,
    },

    data() {
        return {
            page: 0,
            insurance: 1,
            steps: ["Applicant Information", "Driver Information", "Vehicle Information", "Complete"],
        };
    },

    computed: {
        ...mapGetters({
            formApp: ["data"],
            drivers: ["drivers"],
        }),

        getCurrentForm() {
            switch (this.page) {
                case 0:
                    return "submitApplicationInformation";
                case 1:
                    return null;
                default:
                    return "submitApplicationInformation";
            }
        },
    },

    watch: {
        page(value) {
            if (value < 0) {
                this.page = 0;
            }
            if (value > this.steps.length - 1) {
                this.page = this.steps.length - 1;
            }
        },
    },

    methods: {
        submitQuote() {
            if (this.formApp.vehicles.length === 0) {
                return this.$toast.error("Please add a vehicle first");
            }
            this.$http.post("/api/submit", this.formApp).then((data) => {
                this.page += 1;
            });
        },

        goBack() {
            this.page -= 1;
        },

        goForward() {
            const applicationForm = this.$refs.applicationForm.form;
            const driverForm = this.$refs.driverForm;

            if (this.page === 1) {
                const firstDriver = this.$store.getters.drivers[0].valid;
                if (!firstDriver) {
                    this.$toast.error("Please add a driver first");
                    return;
                }
                this.page += 1;
                return;
            }

            if (this.page === 0 && this.drivers.length === 0) {
                const newDriver = createDriver(
                    applicationForm.first_name,
                    applicationForm.middle_name,
                    applicationForm.last_name,
                    applicationForm.dob,
                );

                this.drivers.push(newDriver);

                this.$store.commit("editDriver", this.drivers[0]);
            }

            this.page += 1;
        },
    },
};
</script>