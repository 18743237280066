<template>
    <div>
        <b-form-group :label="label" :invalid-feedback="errors[0] ? errors[0] : null">
            <slot/>
        </b-form-group>
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>
<script>
import { template, templateSettings } from "lodash";

import lang from "@/support/en";

// curly brace syntax
templateSettings.interpolate = /{{([\s\S]+?)}}/g;

const TEMPLATES_MAP = lang.validation;

export default {
    props: {
        label: {
            type: String,
            required: true,
            default: null,
        },
        validations: {
            required: true,
            type: Object|String,
        },
    },

    computed: {
        errors() {
            if(!this.validations) {
                return [];
            }
            if (!this.invalid) {
                return [];
            }

            return Object.keys(this.validations.$params).reduce((errors, validator) => {
                if (!this.validations[validator]) {
                    const compiled = template(TEMPLATES_MAP[validator]);
                    errors.push(compiled(this.validations.$params[validator]));
                }

                return errors;
            }, []);
        },

        invalid() {
            return this.validations.$dirty && this.validations.$invalid;
        },
    },

    render() {
        return this.$scopedSlots.default({
            errors: this.errors,
            invalid: this.invalid,
        });
    },
};
</script>